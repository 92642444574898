import { useMemo } from 'react';
import Select from 'react-select';

import { EnumsSlideLayout } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../../../hooks/useInstance';
import { buildReactSelectStyles } from '../../../../../utils/react-select';
import { type Option } from '../../../../common/Utilities';
import { SlideLayoutIcon } from '../../../../icons/Slide/SlideLayoutIcon';

const INFO_SLIDE_LAYOUT = [
  {
    label: 'Classic',
    value: EnumsSlideLayout.SlideLayoutClassic,
  },
  {
    label: 'Title and text',
    value: EnumsSlideLayout.SlideLayoutTitleAndText,
  },
  {
    label: 'Big title',
    value: EnumsSlideLayout.SlideLayoutBigTitle,
  },
  {
    label: 'Big title + media',
    value: EnumsSlideLayout.SlideLayoutBigTitleMedia,
  },
  {
    label: 'Bullet points',
    value: EnumsSlideLayout.SlideLayoutBulletPoints,
  },
  {
    label: 'Bullet points + media',
    value: EnumsSlideLayout.SlideLayoutBulletPointsMedia,
  },
  {
    label: 'Quote',
    value: EnumsSlideLayout.SlideLayoutQuote,
  },
  {
    label: 'Quote + media',
    value: EnumsSlideLayout.SlideLayoutQuoteMedia,
  },
  {
    label: 'Full host',
    value: EnumsSlideLayout.SlideLayoutFullHost,
  },
  {
    label: 'Iframe',
    value: EnumsSlideLayout.SlideLayoutIframe,
    hidden: true,
  },
  {
    label: 'HTML',
    value: EnumsSlideLayout.SlideLayoutHtml,
    hidden: true,
  },
];

export function isInfoSlideLayout(layout: EnumsSlideLayout): boolean {
  return INFO_SLIDE_LAYOUT.some((option) => option.value === layout);
}

export function InfoSlideLayoutSelect(props: {
  value: EnumsSlideLayout;
  onChange: (value: EnumsSlideLayout) => void;
  includeHidden?: boolean;
}) {
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsSlideLayout>>({
      override: { control: { height: 48 } },
    })
  );

  const options = useMemo(() => {
    if (props.includeHidden) {
      return INFO_SLIDE_LAYOUT;
    }
    return INFO_SLIDE_LAYOUT.filter((option) => !option.hidden);
  }, [props.includeHidden]);

  return (
    <Select<Option<EnumsSlideLayout>>
      options={options}
      value={INFO_SLIDE_LAYOUT.find((option) => option.value === props.value)}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        props.onChange(option.value);
      }}
      formatOptionLabel={(option) => (
        <div className='flex items-center gap-2'>
          <SlideLayoutIcon
            className='w-9 h-6 fill-current'
            layout={option.value}
          />
          {option.label}
        </div>
      )}
      menuPlacement='auto'
      menuPosition='fixed'
    />
  );
}
