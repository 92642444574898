import { type DtoPersonality } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../../../services/api-service';
import { CodeCanvasUtils } from '../../../../GameV2/blocks/CodeCanvas/utils';
import { DialogueUtils } from '../../../../VoiceOver/Dialogue/utils';
export class CodeCanvasEditorUtils {
  static async FetchCode(
    blockId: string,
    codeTimestamp: string
  ): Promise<string> {
    // Fetch from S3, adding timestamp to bypass cache
    const url = CodeCanvasUtils.GetCodeS3Url(blockId, codeTimestamp);
    if (!url) return '';
    const response = await fetch(url);
    if (!response.ok) {
      // Handle case where file might not exist yet or other errors
      if (response.status === 404 || response.status === 403) {
        console.warn(
          `Code file not found or access denied for ${blockId}, starting fresh.`
        );
        return ''; // Return empty string if not found
      } else {
        throw new Error(`Failed to fetch code: ${response.status}`);
      }
    }
    const fetchedCode = await response.text();
    return fetchedCode;
  }

  static async GenerateCode(
    desc: string,
    personalities: DtoPersonality[],
    defaultPersonalityId: string
  ) {
    const speakers = personalities.map((p) => ({
      id: p.id,
      name: p.name,
      description: p.description,
    }));

    const resp = await apiService.promptTemplate.runTemplate({
      promptTemplateMappingKey: 'slide/generate-html',
      variables: {
        desc,
        speakers: JSON.stringify(speakers),
      },
      maxTokens: 15360,
    });

    let dialogue, html;
    const args = resp.data.toolCalls?.at(0)?.args;
    if (args) {
      dialogue = args.dialogue;
      html = args.html;
    } else {
      // Extract dialogue (JSON) and html from content using regex
      const content = resp.data.content || '';
      const dialogueMatch = content.match(/```json\n([\s\S]*?)\n```/);
      const htmlMatch = content.match(/```html\n([\s\S]*?)\n```/);

      if (!dialogueMatch || !htmlMatch) {
        throw new Error('Could not extract dialogue and html from content');
      }
      try {
        dialogue = JSON.parse(dialogueMatch[1]);
        dialogue = DialogueUtils.AISchemaToDialogue(dialogue);
      } catch (e) {
        throw new Error('Failed to parse dialogue JSON');
      }
      html = htmlMatch[1];
    }

    if (!dialogue || !html) {
      throw new Error('Failed to get dialogue or html content');
    }

    dialogue = DialogueUtils.EnsurePersonalities(
      dialogue,
      new Map(personalities.map((p) => [p.id, p])),
      defaultPersonalityId
    );

    return {
      dialogue,
      html,
    };
  }
}
