import { type Content, type Editor, mergeAttributes, Node } from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useRef } from 'react';
import Select from 'react-select';

import {
  EnumsFillInTheBlanksAnswerStyle,
  EnumsFillInTheBlanksSegmentType,
  type ModelsFillInTheBlanksSegment,
} from '@lp-lib/api-service-client/public';
import { type FillInTheBlanksBlock } from '@lp-lib/game';

import { getLogger } from '../../../logger/logger';
import { uuidv4 } from '../../../utils/common';
import { buildReactSelectStyles } from '../../../utils/react-select';
import type { Option } from '../../common/Utilities';
import { PointsInput } from '../../Game/Blocks/Common/Editor/PointsUtilities';
import { FFriendlyEditableText } from '../../GameV2/design/Editable';
import { SparkBlockBackground } from '../../GameV2/design/SparkBackground';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { BlockMusicSelect } from './Shared/BlockMusicSelect';
import {
  SparkBackgroundMediaField,
  SparkBackgroundSelect,
} from './Shared/SparkBackgroundSelect';
import { type TrainingSlideEditorProps } from './types';

function AnswerStyleSelect(props: {
  value?: EnumsFillInTheBlanksAnswerStyle | null;
  onChange: (value: EnumsFillInTheBlanksAnswerStyle) => void;
}) {
  const options = useMemo(() => {
    return [
      {
        label: 'Multiple Choice',
        value: EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleChoice,
      },
      {
        label: 'Type the Answer',
        value: EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleTyping,
      },
    ];
  }, []);

  const value = useMemo(() => {
    if (options.length === 0) return undefined;
    if (!props.value) return options[0];

    return options.find((o) => o.value === props.value);
  }, [props.value, options]);

  const styles = useMemo(
    () => buildReactSelectStyles<Option<EnumsFillInTheBlanksAnswerStyle>>(),
    []
  );

  return (
    <Select<Option<EnumsFillInTheBlanksAnswerStyle>, false>
      options={options}
      value={value}
      styles={styles}
      classNamePrefix='select-box-v2'
      isSearchable
      onChange={(v) => {
        if (!v) return;
        props.onChange(v.value);
      }}
    />
  );
}

const QuestionNode = Node.create({
  name: 'question',
  topNode: true,
  content: 'segment+',

  parseHTML() {
    return [{ tag: 'question' }];
  },
});

const SegmentNode = Node.create({
  name: 'segment',
  group: 'segment',
  content: 'text*',
  inline: true,
  selectable: true,
  draggable: false,

  addAttributes() {
    return {
      id: {
        default: uuidv4(),
      },
      type: {
        default: 'text', // 'text' | 'blank'
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'segment',
        getAttrs(node) {
          return {
            id: node.dataset.id,
            type: node.dataset.type,
          };
        },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'segment',
      mergeAttributes(
        {
          'data-id': node.attrs.id,
          'data-type': node.attrs.type,
        },
        node.attrs.type === 'blank'
          ? {
              class: 'underline text-icon-gray',
            }
          : {},
        HTMLAttributes
      ),
      0,
    ];
  },
});

function segmentsToEditor(segments: ModelsFillInTheBlanksSegment[]): Content {
  if (segments.length === 0) {
    return null;
  }

  return {
    type: 'question',
    content: segments.map((segment) => ({
      type: 'segment',
      attrs: {
        id: segment.id,
        type: segment.type,
      },
      content: [{ type: 'text', text: segment.text }],
    })),
  };
}

function editorToSegments(editor: Editor): ModelsFillInTheBlanksSegment[] {
  const segments: ModelsFillInTheBlanksSegment[] = [];
  editor.state.doc.descendants((node) => {
    if (node.type.name === 'segment') {
      const text = node.textContent;
      if (!text) return;

      if (node.attrs.type === 'blank') {
        segments.push({
          id: node.attrs.id ?? uuidv4(),
          type: EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank,
          text,
        });
      } else {
        segments.push({
          id: node.attrs.id ?? uuidv4(),
          type: EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText,
          text,
        });
      }
    }
  });

  return segments;
}

function segmentsEqual(
  a: ModelsFillInTheBlanksSegment[],
  b: ModelsFillInTheBlanksSegment[]
) {
  if (a.length !== b.length) return false;
  return a.every(
    (segment, index) =>
      segment.id === b[index].id &&
      segment.text === b[index].text &&
      segment.type === b[index].type
  );
}

const Text = Node.create({
  name: 'text',
  group: 'inline',
  inline: true,
});

export function ChoiceButton(props: {
  backgroundColor: string;
  children: React.ReactNode;
}) {
  const { backgroundColor, children } = props;

  return (
    <div
      className='
        relative w-30 h-30 rounded-full
      '
      style={{
        backgroundColor,
        boxShadow: '20px -40px 40px 0px rgba(0, 0, 0, 0.50) inset',
      }}
    >
      <div
        className='absolute inset-0 rounded-full'
        style={{
          background:
            'linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,0.2) 100%)',
        }}
      ></div>
      <div
        className='absolute inset-0 bottom-[6px] rounded-full transition-all duration-300'
        style={{
          backgroundColor,
          boxShadow: '20px -40px 40px 0px rgba(0, 0, 0, 0.50) inset',
        }}
      ></div>
      <div
        className={`relative w-full h-full p-5 overflow-hidden flex items-center justify-center text-xs lg:text-base break-words font-bold text-center`}
      >
        {children}
      </div>
    </div>
  );
}

export function FillInTheBlanksBlockEditor(
  props: TrainingSlideEditorProps<FillInTheBlanksBlock>
) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const logger = getLogger().scoped('fill-in-blanks-editor');

  const segments = props.block.fields.segments;

  const content = useMemo(() => segmentsToEditor(segments), [segments]);

  const blanks = segments.filter(
    (s) =>
      s.type === EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank
  );

  const isToolTipLikelyVisible = useRef(false);

  const editor = useEditor({
    extensions: [
      QuestionNode,
      SegmentNode,
      Text,
      Placeholder.configure({
        placeholder:
          "Type a sentence or phrase here. You'll then select the word or words to hide.",
        // TODO(jose): This feels like a hack to make the placeholder text not disappear, but im not sure what made it disappear in the first place
        // https://github.com/ueberdosis/tiptap/issues/2659
        emptyEditorClass:
          'cursor-text before:content-[attr(data-placeholder)] before:absolute before:top-3 before:left-3 before:text-mauve-11 before:opacity-50 before-pointer-events-none',
      }),
    ],
    editorProps: {
      attributes: {
        class:
          'w-full h-full appearance-none outline-none space-y-2 text-center leading-relaxed',
      },
    },
    immediatelyRender: true,
    content: content,
    onUpdate: ({ editor }) => {
      // This is unfortunate since it triggers a rerender on each keypress. But
      // it's necessary to handle the use case of reflecting changes to the text
      // in the choice buttons. It also allows the tooltip actions to avoid
      // needing to call update methods themselves.
      const editorSegments = editorToSegments(editor);
      onChange('segments', editorSegments);
    },
    onBlur: ({ editor }) => {
      // Optimization: this will fire even when interacting with the tooltips,
      // so we hook into their visibility check to avoid a double update to the
      // server when interacting with tooltips (without this check, the updates
      // would be STALE DATA (here), NEW DATA (tooltip edit)).
      if (isToolTipLikelyVisible.current) return;
      const editorSegments = editorToSegments(editor);
      onBlur('segments', editorSegments);
      onChange('segments', editorSegments);
    },
  });

  useEffect(() => {
    // Do a shape/data comparison so that we only reset the editor content if
    // the data is truly different. Could use a deepEqual, but I considered that
    // we might want to exclude the ID from the comparison; initially the ID was
    // different during each transformation.
    const editorSegments = editorToSegments(editor);
    if (segmentsEqual(segments, editorSegments)) {
      return;
    }

    // If the content changes due to an incoming prop change (e.g. backend
    // server update), consider it the newest content and update the editor.
    editor.commands.setContent(content);
  }, [editor, content, segments]);

  if (!editor) return null;

  const handleMarkAsBlank = () => {
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to);

    editor
      .chain()
      .focus()
      .deleteSelection()
      .insertContent({
        type: 'segment',
        attrs: {
          id: uuidv4(),
          type: EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank,
        },
        content: [
          {
            type: 'text',
            text,
          },
        ],
      })
      .run();
  };

  const handleUnmarkBlank = () => {
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to);

    editor
      .chain()
      .focus()
      .deleteSelection()
      .insertContent({
        type: 'segment',
        attrs: {
          id: uuidv4(),
          type: EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText,
        },
        content: [
          {
            type: 'text',
            text,
          },
        ],
      })
      .run();
  };

  const renderBubbleMenuContent = () => {
    let isBlankSegment = false;
    try {
      const { from, to } = editor.state.selection;

      // Check every position in the selection for a blank segment
      let pos = from;
      while (pos <= to && !isBlankSegment) {
        const resolvedPos = editor.state.doc.resolve(pos);

        // Check each parent node until we find a segment
        let depth = resolvedPos.depth;
        while (depth > 0 && !isBlankSegment) {
          const node = resolvedPos.node(depth);
          if (node.type.name === 'segment' && node.attrs.type === 'blank') {
            isBlankSegment = true;
            break;
          }
          depth--;
        }

        pos++;
      }
    } catch (e) {
      logger.error('Error checking segment type:', e);
    }

    return isBlankSegment ? (
      <button
        type='button'
        className='btn-secondary px-3 py-1'
        onClick={handleUnmarkBlank}
      >
        Unmark Blank
      </button>
    ) : (
      <button
        type='button'
        className='btn-secondary px-3 py-1'
        onClick={handleMarkAsBlank}
        disabled={blanks.length >= 4}
      >
        Mark as Blank
      </button>
    );
  };

  return (
    <>
      <SparkBlockBackground block={props.block} position='absolute' noVideo />
      <div className='relative w-full h-full min-h-0 flex flex-col'>
        <main className='w-full flex-1 min-h-0 px-10 py-5 flex flex-col gap-5 sm:gap-10'>
          <div className='text-center text-sms italic text-icon-gray'>
            Fill in the blanks
          </div>

          <div className='text-white flex flex-col gap-5'>
            <BubbleMenu
              editor={editor}
              shouldShow={({ editor }) => {
                // The check for `shouldShow` executes _after_ the updateDelay
                // is expended, so it appears safe to measure visibilty this way
                // (there is no built-in method to do so as far as I can tell).
                const active = !editor.state.selection.empty;
                isToolTipLikelyVisible.current = active;
                return active;
              }}
            >
              {renderBubbleMenuContent()}
            </BubbleMenu>

            <EditorContent editor={editor} style={{}} />

            {segments.length > 0 && (
              <div className='text-sms italic text-icon-gray text-center'>
                Select the words (up to 4) the learner will fill in above
              </div>
            )}

            {block.fields.answerStyle ===
              EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleChoice &&
              segments.length > 0 && (
                <div className='flex flex-col gap-5'>
                  <div className='flex flex-wrap gap-2 justify-center items-center'>
                    {blanks.map((blank) => (
                      <ChoiceButton key={blank.id} backgroundColor='#0029AE'>
                        <span className='text-[#81BCFF] cursor-not-allowed'>
                          {blank.text}
                        </span>
                      </ChoiceButton>
                    ))}

                    {Array.from({ length: Math.max(0, 4 - blanks.length) }).map(
                      (_, index) => (
                        <ChoiceButton
                          key={`wrong-${index}`}
                          backgroundColor='#383838'
                        >
                          <FFriendlyEditableText
                            value={block.fields.wrongAnswers[index] || ''}
                            placeholder='+ Option'
                            onBlur={(value) => {
                              const wrongAnswers = [
                                ...block.fields.wrongAnswers,
                              ];
                              wrongAnswers[index] = value;
                              onChange('wrongAnswers', wrongAnswers);
                              onBlur('wrongAnswers', wrongAnswers);
                            }}
                            className='outline-none cursor-text whitespace-pre-wrap contenteditable-placeholder text-icon-gray'
                          />
                        </ChoiceButton>
                      )
                    )}
                  </div>

                  <div className='text-sms italic text-icon-gray text-center'>
                    The learner will choose from the words above
                  </div>
                </div>
              )}
          </div>
        </main>
      </div>
    </>
  );
}

export function FillInTheBlanksBlockSidebarEditor(
  props: TrainingSlideEditorProps<FillInTheBlanksBlock>
) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={props.block.fields.personalityId}
          isClearable
        />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Answer Style</p>
        <AnswerStyleSelect
          value={props.block.fields.answerStyle}
          onChange={(value) => {
            onChange('answerStyle', value);
            onBlur('answerStyle', value);
          }}
        />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Block Intro</p>
        <BlockIntroSelect
          value={props.block.fields.intro}
          onChange={(value) => {
            onChange('intro', value);
            onBlur('intro', value);
          }}
        />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Background</p>
        <SparkBackgroundSelect
          value={props.block.fields.sparkBackgroundOption}
          onChange={(value) => {
            onChange('sparkBackgroundOption', value);
            onBlur('sparkBackgroundOption', value);
          }}
        />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={props.block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>
          Points Per Correct Answer
        </p>
        <PointsInput
          defaultValue={props.block.fields.pointsPerCorrect}
          max={1000}
          min={0}
          placeholder={'Max 1000 points'}
          onChange={(value) => {
            onChange('pointsPerCorrect', value);
            onBlur('pointsPerCorrect', value);
          }}
        />
      </label>
    </div>
  );
}

export function FillInTheBlanksBlockEditorUnder(
  props: TrainingSlideEditorProps<FillInTheBlanksBlock>
) {
  return (
    <div className='flex flex-col gap-5 text-white'>
      <SparkBackgroundMediaField {...props} />
    </div>
  );
}
