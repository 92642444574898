import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';
import { useToggle } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import { assertExhaustive, type ScenarioBlock } from '@lp-lib/game';

import { fromMediaDTO } from '../../../utils/api-dto';
import { ImagePickPriorityHighToLow } from '../../../utils/media';
import { MediaUtils } from '../../../utils/media';
import { PointsInput } from '../../Game/Blocks/Common/Editor/PointsUtilities';
import { ArrowDownIcon } from '../../icons/Arrows';
import { ArrowUpIcon } from '../../icons/Arrows/Default';
import { SwitcherControlled } from '../../Switcher';
import { DialogueEditor } from '../../VoiceOver/Dialogue/DialogueEditor';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { usePersonality } from '../../VoiceOver/usePersonalities';
import { useTrainingSlideEditor } from './hooks';
import {
  GenerateRubricButton,
  ScenarioBlockEditorRubric,
} from './ScenarioBlockEditorRubric';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { useTrainingEditorCoursePersonalityIds } from './TrainingEditorControlAPI';
import { type TrainingSlideEditorProps } from './types';

const tabs = ['character', 'rubric'] as const;

type Tab = (typeof tabs)[number];

type State = {
  activeTab: Tab;
};

const state = proxy<State>({
  activeTab: 'rubric',
});

export function ScenarioBlockEditorTabs() {
  const { activeTab } = useSnapshot(state);

  useEffect(() => {
    return () => {
      state.activeTab = 'rubric';
    };
  }, []);

  return (
    <div className='p-2 flex items-center gap-2.5 text-white'>
      {tabs.map((tab) => (
        <button
          type='button'
          key={tab}
          className={`
            w-25 h-8 bg-secondary rounded-lg
            border ${activeTab === tab ? 'border-white' : 'border-transparent'}
          `}
          onClick={() => {
            state.activeTab = tab;
          }}
        >
          {capitalize(tab)}
        </button>
      ))}
    </div>
  );
}

function CharacterTab(props: TrainingSlideEditorProps<ScenarioBlock>) {
  const { block } = props;

  const coursePersonalityIds = useTrainingEditorCoursePersonalityIds();
  const firstEntry = block.fields.question?.entries?.at(0);
  const personalityId = firstEntry?.personalityId;
  const script = firstEntry?.script;
  const personality = usePersonality(personalityId, {
    fallbackToId: coursePersonalityIds.at(0),
    fallbackToDefault: true,
  });

  if (personality?.avatarId) {
    const mediaUrl = MediaUtils.PickMediaUrl(
      fromMediaDTO(personality?.profileImage?.media),
      {
        priority: ImagePickPriorityHighToLow,
      }
    );
    if (mediaUrl) {
      return (
        <div className='absolute inset-0'>
          <img src={mediaUrl} alt={''} className='w-full h-full object-cover' />
        </div>
      );
    }
  }
  if (script) {
    return (
      <div className='w-full h-full p-10 sm:p-13 lg:p-15 flex items-center justify-center'>
        <p className='text-white text-2xl font-bold text-center'>{script}</p>
      </div>
    );
  }

  return null;
}

function RubricTab(props: TrainingSlideEditorProps<ScenarioBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col items-center p-5 md:p-10 overflow-auto scrollbar'>
      <ScenarioBlockEditorRubric
        value={props.block.fields.rubric}
        onChange={(rubric) => {
          onChange('rubric', rubric);
          onBlur('rubric', rubric);
        }}
      />
    </div>
  );
}

export function ScenarioBlockEditor(
  props: TrainingSlideEditorProps<ScenarioBlock>
) {
  const { activeTab } = useSnapshot(state);

  switch (activeTab) {
    case 'character':
      return <CharacterTab {...props} />;
    case 'rubric':
      return <RubricTab {...props} />;
    default:
      assertExhaustive(activeTab);
      return null;
  }
}

export function ScenarioBlockSidebarEditor(
  props: TrainingSlideEditorProps<ScenarioBlock>
) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const [showAdvanced, toggleShowAdvanced] = useToggle(false);

  return (
    <div className='w-full flex flex-col items-center gap-5 text-white'>
      <div className='flex flex-col gap-1'>
        <DialogueEditor
          key={block.id}
          title='AI Question / Objection'
          subtitle='The AI character prompts a trainee with a single objection or question.'
          editorContainerStyles={{
            background: 'bg-black',
            border: 'border border-secondary',
          }}
          value={props.block.fields.question}
          onChange={(value) => {
            onChange('question', value);
            onBlur('question', value);
          }}
          offlineRendering
          singlePersonality
        />
      </div>

      <div className='flex flex-col gap-1'>
        <p className='text-base font-bold'>Golden Standard Response</p>
        <p className='text-3xs text-icon-gray'>
          Enter the perfect answer to the objection. This guides the evaluation.
        </p>
        <textarea
          className='w-full h-45 field m-0 p-2'
          placeholder='Enter the perfect answer to the objection.'
          defaultValue={block.fields.goldenAnswer}
          // Ensure the component remounts if the defaultValue changes, such as
          // due to an update from the server. If the field is untouched during
          // the session, the browser will reflect defaultValue changes. But
          // once the user makes a single change, future defaultValue updates
          // will be ignored.
          key={block.fields.goldenAnswer}
          onBlur={(e) => {
            onChange('goldenAnswer', e.target.value);
            onBlur('goldenAnswer', e.target.value);
          }}
        />
      </div>

      <GenerateRubricButton
        blockId={block.id}
        question={block.fields.question?.entries?.at(0)?.script ?? ''}
        goldenAnswer={block.fields.goldenAnswer}
        rubric={block.fields.rubric ?? null}
        onGenerate={(rubric) => {
          onChange('rubric', rubric);
          onBlur('rubric', rubric);
        }}
      />

      <div className='w-full flex flex-col gap-1'>
        <p className='text-base font-bold'>Narration Voice</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={props.block.fields.personalityId}
          isClearable
        />
        <p className='text-3xs text-icon-gray'>
          Select the voice for the scenario intro & feedback.
        </p>
      </div>

      <div className='w-full flex flex-col gap-1'>
        <p className='text-base text-white font-bold mb-1'>
          Select Total Points
        </p>
        <PointsInput
          defaultValue={block.fields.points}
          max={1000}
          min={0}
          placeholder={'Max 1000 points'}
          onChange={(value) => {
            onChange('points', value);
            onBlur('points', value);
          }}
        />
        <p className='text-2xs font-medium text-icon-gray'>
          Select the total points earned for this scenario.Formula: Total Points
          × (Checkmarks Received / Total Possible Checkmarks) per grading
          rubric.
        </p>
      </div>

      <button
        type='button'
        className='w-full btn flex items-center gap-2'
        onClick={() => toggleShowAdvanced()}
      >
        <p className='text-base font-bold'>Advanced Settings</p>
        {showAdvanced ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>

      {showAdvanced && (
        <>
          <div className='flex flex-col gap-1'>
            <p className='text-base font-bold'>Skip Grading Rubric</p>
            <div className='flex items-center justify-between gap-2'>
              <p className='text-sms flex-1'>
                Toggle on to skip the grading rubric
              </p>
              <SwitcherControlled
                name={`${block.id}-skip-rubric`}
                checked={block.fields.skipRubric}
                onChange={(checked: boolean): void => {
                  onChange('skipRubric', checked);
                  onBlur('skipRubric', checked);
                }}
              />
            </div>
          </div>

          <div className='flex flex-col gap-1'>
            <p className='text-base font-bold'>Slide Intro</p>
            <BlockIntroSelect
              value={props.block.fields.intro}
              onChange={(value) => {
                onChange('intro', value);
                onBlur('intro', value);
              }}
            />
            <p className='text-3xs text-icon-gray'>
              Configure what is said or shown before this slide begins.
            </p>
          </div>

          <div className='flex flex-col gap-4'>
            <p className='text-base font-bold'>Delivery Evaluations</p>

            <div className='flex flex-col gap-1'>
              <div className='flex items-center justify-between gap-2'>
                <p className='text-sms font-bold'>Words Per Minute (WPM)</p>
                <SwitcherControlled
                  name={`${block.id}-wpm-enabled`}
                  checked={block.fields.showWPM}
                  onChange={(checked: boolean): void => {
                    onChange('showWPM', checked);
                    onBlur('showWPM', checked);
                  }}
                />
              </div>

              <p className='text-3xs text-icon-gray'>
                When enabled, we measure your words per minute, aiming for an
                ideal of 120-150 WPM.
              </p>
            </div>

            <div className='flex flex-col gap-1'>
              <div className='flex items-center justify-between gap-2'>
                <p className='text-sms font-bold'>Filler Words</p>
                <SwitcherControlled
                  name={`${block.id}-filler-words-enabled`}
                  checked={block.fields.showFillerWords}
                  onChange={(checked: boolean): void => {
                    onChange('showFillerWords', checked);
                    onBlur('showFillerWords', checked);
                  }}
                />
              </div>
              <p className='text-3xs text-icon-gray'>
                When enabled, we track the number of filler words (e.g., 'umm,'
                'like,' 'alright') in your response.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
